import { useEffect, useRef, useState, useCallback } from 'react'
import { useAnalytics } from '../../../../common/analytics'
import useOnViewport from 'src/common/hooks/useOnViewport'
import { checkIcon } from './index.module.scss'
import {
  estimatedPaymentsReferenceTable,
  paymentsReferenceTable as deafultPaymentValues,
} from '../SliderCTA/data'
import { numberFormat } from 'src/common/utils'
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver'
import { useNavigateToApply } from 'src/common/hooks/useNavigateToApply'
import Img from 'src/components/shared/Img'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { featureToggles } from 'src/common/constants/featureToggles'
import Modal from 'src/components/shared/Modal'
import { cfosDisclousures } from 'src/components/layouts/consts'
import { cn } from 'src/utils/cn'

const amountFormatOptions = {
  style: 'currency',
  currency: 'USD',
}

function getTimeFormat(monthCount) {
  monthCount = Number(monthCount)
  function getPlural(number, word) {
    return (number === 1 && word.one) || word.other
  }

  var months = { one: 'mos', other: 'mos' },
    years = { one: 'year', other: 'yrs' },
    m = monthCount % 12,
    y = Math.floor(monthCount / 12),
    result = []
  if (m < 1) {
    return `${y} years`
  } else {
    y && result.push(y + ' ' + getPlural(y, years))
    m && result.push(m + ' ' + getPlural(m, months))
  }

  return result.join(' ')
}

const DebtReliefCalculator = ({
  data,
  initialAmount = 25000,
  cfosData,
  isCfosDisclousuresVariationActive,
}) => {
  const { track } = useAnalytics()
  const { showWarningSection = true } = data
  const wasInViewport = useRef(false)
  const [isOnViewport] = useOnViewport('debt-assessment', true)

  const { getExperimentVariation } = useFeatureFlags()
  const homepageDropDownFloatingFooter = getExperimentVariation(
    experimentSectionByVariation[
      featureToggles.HOMEPAGE_DROP_DOWN_FLOATING_FOOTER
    ]
  )

  const isHomepageTestOn = homepageDropDownFloatingFooter === 'test'

  const useCfosData = cfosData && isCfosDisclousuresVariationActive
  const paymentsReferenceTable = useCfosData ? cfosData : deafultPaymentValues
  const defaultValue =
    paymentsReferenceTable[initialAmount] &&
    paymentsReferenceTable[initialAmount][0]
      ? initialAmount
      : 25000
  const [selectedAmount, setSelectedAmount] = useState(defaultValue)
  const values = Object.keys(paymentsReferenceTable)
  const onViewH2Id = 'debt-relief-calculator'
  const { navigateToApply, getUrl } = useNavigateToApply({
    estimated_debt: selectedAmount,
  })

  const [showModal, setShowModal] = useState(false)

  const handleModalOpen = useCallback(() => {
    setShowModal(true)
    document.body.style.overflow = 'hidden'
  }, [setShowModal])

  const handleModalClose = useCallback(() => {
    setShowModal(false)
    document.body.style.overflow = 'unset'
  }, [setShowModal])

  useEffect(() => {
    if (!wasInViewport.current && isOnViewport) {
      track(
        {
          additional_info: { section: 'data-visualization' },
          track_event: 'section_view',
          event_type: 'track',
        },
        {},
        'section_view'
      )
      wasInViewport.current = true
    }
  }, [track, isOnViewport])

  const { ref } = useIntersectionObserver({
    threshold: 0.5,
    onChange: (isIntersecting) => {
      if (!isIntersecting) return
      track(
        { additional_info: { section: onViewH2Id }, event_type: 'track' },
        {},
        'section_view'
      )
    },
    freezeOnceVisible: true,
  })

  const handleClick = (e, params = {}) => {
    e.preventDefault()
    const variationText = e.target.innerText
    const data = {
      nav_link_section: 'Debt relief calc',
      page_section: 'You may free up $ on your monthly payment',
      click_type: 'Button Click',
      click_id: variationText,
      click_text: `FDR-Web | ${variationText}`,
      form_ss_amount: +selectedAmount,
      track_event: 'button_click',
      ...params,
    }

    track(
      data,
      {
        event_type: 'conversion',
      },
      'button_click'
    )

    navigateToApply()
  }

  const getLinePorcent = (index, limitIndex) => {
    const rowValues = paymentsReferenceTable[selectedAmount]

    return Math.trunc((rowValues[index] * 100) / rowValues[limitIndex])
  }

  const getDetailItem = (info) => (
    <div className="w-full p-4">
      <div className="pb-3 text-left text-xl font-bold">{info.title}</div>
      {info.lines.map((line, index) => (
        <div
          className="my-2 flex items-center  justify-between"
          key={`line-detail-${index}`}
        >
          <div
            className={`flex items-center rounded-md px-1 ${line.className} py-4 text-sm`}
            style={{
              ...(line.indexRefernece
                ? {
                    background: `
                linear-gradient(
                  to right,
                  ${line.bgColor} 0%,
                  ${line.bgColor} ${getLinePorcent(
                      line.val,
                      line.indexRefernece
                    )}%,
                  transparent ${
                    100 - getLinePorcent(line.val, line.indexRefernece)
                  }%,
                  transparent 100%
                )
              `,
                  }
                : {}),
            }}
          >
            {line.text}{' '}
            {line.iconCheck && <i className={`${checkIcon} ml-2`} />}
          </div>
          <span className="text-right text-sm	font-bold">
            {info.useStaticValues
              ? useCfosData
                ? getTimeFormat(
                    paymentsReferenceTable[selectedAmount][line.indexVal]
                  )
                : line.val
              : numberFormat(
                  paymentsReferenceTable[selectedAmount][line.val],
                  amountFormatOptions
                )}
            {line.unit}
            <p>{line?.extra}</p>
          </span>
        </div>
      ))}
    </div>
  )

  return (
    <>
      <div id="debt-assessment" />
      <div
        className="flex flex-col flex-wrap bg-gray-75 p-4 lg:flex-row lg:px-28 lg:py-10"
        id="data-visualization-debt-relief-calculator"
      >
        <div className="flex w-full flex-col flex-wrap justify-center gap-x-primary-xs gap-y-6 rounded-lg bg-white px-4 py-8 lg:flex-row lg:gap-x-primary-md lg:gap-y-8 lg:px-10 lg:py-12 ">
          <h2
            className="text-primary-xs font-normal leading-tight tracking-normal text-blue-700 md:leading-10"
            ref={ref}
          >
            {data.title.replace(
              '${amount}',
              numberFormat(
                paymentsReferenceTable[selectedAmount][0] -
                  paymentsReferenceTable[selectedAmount][2],
                amountFormatOptions
              )
            )}
          </h2>
          <div className="w-full rounded-lg py-5 text-center md:shadow-sm xl:mb-0">
            <p
              className={`mt-2 text-base font-normal lg:text-lg ${'text-gray-555'}`}
            >
              {data.subTitle}
            </p>
            <form className="mx-auto mb-8 mt-4 max-w-sm">
              <select
                id="amount"
                className="text-md block w-full rounded-lg border-2 border-blue-500 p-2.5 font-bold text-blue-700"
                value={selectedAmount}
                onChange={(e) => setSelectedAmount(e.target.value)}
              >
                {values.map((val) => (
                  <option
                    key={`vail-item-${val}`}
                    className="text-blue-700"
                    value={val}
                  >
                    {numberFormat(val, amountFormatOptions)}
                  </option>
                ))}
              </select>
            </form>
            <div className="flex w-full flex-col items-center justify-around md:flex-row">
              {data.items.map((info, index) => (
                <div className="w-full" key={`info-item-${index}`}>
                  {getDetailItem(info)}
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full justify-center text-center lg:mt-2">
            <a
              onClick={handleClick}
              href={getUrl()}
              className={cn(
                'max-w-0.5 group flex w-full cursor-pointer items-center justify-center gap-x-3 rounded-lg px-8 py-3 sm:w-1/2 md:w-1/3',
                isHomepageTestOn ? 'bg-blue-585' : 'bg-red-850'
              )}
            >
              <span className="text-lg font-bold text-white">
                {data.buttonText ||
                  `See if you save ${numberFormat(
                    paymentsReferenceTable[selectedAmount][0] -
                      paymentsReferenceTable[selectedAmount][2],
                    amountFormatOptions
                  )}/mo`}
              </span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-white stroke-2"
              >
                <g className="-translate-x-2 transition-transform duration-100 ease-in-out group-hover:translate-x-0">
                  <path d="M11.5 22L21.5 12L11.5 2" stroke-width="4" />
                  <line
                    className="opacity-0 transition-opacity duration-75 ease-in-out group-hover:opacity-100"
                    x1="21"
                    y1="12"
                    x2="1"
                    y2="12"
                    stroke-width="4"
                  />
                </g>
              </svg>
            </a>
          </div>
          {isCfosDisclousuresVariationActive ? (
            <div className="w-full text-center">
              <a
                onClick={handleModalOpen}
                className="cursor-pointer text-neutral-150 underline underline-offset-4"
              >
                How are these numbers calculated?
              </a>
              {showModal ? (
                <Modal
                  bodyClassName="mb-4 mt-9 "
                  onClose={handleModalClose}
                  open={showModal}
                  className="w-100 max-w-[450px] lg:w-[30%]"
                  hasDistanceFromTop={true}
                >
                  <div className="p-5">
                    <span className="text-[32px] font-bold">
                      How are these numbers calculated?
                    </span>
                    <p className="mt-5 whitespace-pre-line text-left text-14 text-gray-500">
                      {cfosDisclousures}
                    </p>
                    <div className="flex justify-end">
                      <a
                        onClick={handleModalClose}
                        className="group flex w-full items-center justify-center gap-x-3 rounded-lg bg-blue-560 p-3 lg:w-fit"
                      >
                        <span className="whitespace-nowrap text-lg font-bold text-white">
                          Go back
                        </span>
                      </a>
                    </div>
                  </div>
                </Modal>
              ) : null}
            </div>
          ) : null}
          {showWarningSection && (
            <div className="w-full border-l-4 border-red-500 px-4">
              <h3 className="flex items-center font-bold text-red-500">
                <Img
                  src="/next-assets/icons/clock-sand.svg"
                  className={'h-[16px] w-[16px]'}
                  priority
                />
                <span className="ml-2">Don&apos;t wait!</span>
              </h3>
              <p className="py-4">
                If you wait 3 months, you are estimated to pay{' '}
                <span className="font-bold">
                  {numberFormat(
                    estimatedPaymentsReferenceTable[selectedAmount][0],
                    amountFormatOptions
                  )}{' '}
                  more
                </span>{' '}
                in total debt, and{' '}
                <span className="font-bold">
                  {numberFormat(
                    estimatedPaymentsReferenceTable[selectedAmount][1],
                    amountFormatOptions
                  )}{' '}
                  more each month
                </span>{' '}
                to get rid of your debt.
              </p>
              <a
                href={`https://www.freedomdebtrelief.com/home/?from=fdr&estimated_debt=${selectedAmount}`}
                className="cursor-pointer"
              >
                Explore debt solutions
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DebtReliefCalculator
